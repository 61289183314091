.App {
	margin: 5px;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media ( prefers-reduced-motion : no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

.nobreak {
	padding-top: 3px;
	display: inline;
	white-space: nowrap;
}

.menuicon {
	color: white;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
	display: block;
	cursor: pointer;
	display: inline;
}

.menuicon:hover {
	background-color: black;
	color: white;
}

.titlelogocontainer {
	padding-left: calc(50% - 60px);
}

.titlelogo {
	padding-top: 5px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.selected {
	background-color: #ede1e1;
}

.timefrom {
	background-color: #8ae234;
}

.timefrom.selected {
	background-color: #4e9a06;
}

.object-id {
	background-color: yellow;
	text-align: right;
}

.object-id.selected {
	background-color: #ebc334;
	text-align: right;
}

.pagination {
	background-color: lightgrey;
	text-align: right;
}

.pagination.selected {
	background-color: darkgrey;
	text-align: right;
}

.bib {
	font-weight: bold;
	text-align: right;
}

.buttonLabel {
	padding-left: 3px;
	padding-right: 8px;
}

table, th, td {
	padding: 3px;
}

.resultdisplayrow {
	font-size: 32px;
}

.resultdisplaytable {
	width: 100%;
}

.printresult {
	padding-left: 18px;
	padding-right: 18px;
}

.readinglist td {
	padding-left: 8px;
	padding-right: 8px;
}

#bib {
	width: 60px;
}

/*  Navbar */

/* Style the navigation menu */
.topnav {
	overflow: hidden;
	background-color: #333;
	position: relative;
}

/* Hide the links inside the navigation menu (except for logo/home) */
.topnav #myLinks {
	display: none;
}

/* Style navigation menu links */
.topnav a {
	color: white;
	padding: 14px 16px;
	text-decoration: none;
	font-size: 17px;
	display: block;
}

/* Style the hamburger menu */
.topnav span.icon {
	background: red;
	display: block;
	position: absolute;
	right: 0;
	top: 0;
}

/* Add a grey background color on mouse-over */
.topnav a:hover {
	background-color: black;
	color: white;
}

/* Style the active link (or home/logo) */
.active {
	background-color: #04AA6D;
	color: white;
}

.topnav H1 {
	display: inline;
	color: white;
	text-align: center;
}

a.nobreak {
	display: inline;
}

.mypage_in_hereader {
	float: right;
}

.top_save_button {
	float: right;
}

.notpaid {
	background-color: rgb(249, 130, 108);
	font-weight: bold;
}

.mypage {
	padding-left: 18px;
	padding-right: 18px;
	padding-top: 18px;
	padding-bottom: 18px;
}

.quizlist td {
	padding-left: 8px;
	padding-right: 8px;
	font-size: 20px;
}

.numberInQueue {
	padding-left: 8px;
	padding-right: 8px;
	padding-top: 2px;
	padding-bottom: 2px;
	background-color: rgb(249, 130, 108);
}

.errormessage {
	color: red;
}

.guestlist td {
	border-top: 1px solid;
	border-color: black;
}

.guestlisttable {
	width: 100%;
}

.guestListName {
	font-weight: bold;
}

.modal {
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.25);
}

.modal_content {
	background-color: white;
	position: absolute;
	top: 2%;
	left: 5%;
	width: 70%;
	padding: 20px;
	border-radius: 5px;
	border: 2px solid black;
}

.close {
	color: Black;
	float: right;
	font-size: 30px;
}

.close:hover {
	color: cyan;
	cursor: pointer;
}

.version {
	position: fixed;
	right: 0;
	z-index: 1;
	width: 23px;
	height: 50px;
	top: 7px;
	color: white;
	font-size: 7px;
}

.reload {
	position: fixed;
	right: 0;
	z-index: 1;
	width: 15px;
	height: 50px;
	top: 43px;
	color: white;
	font-size: 7px;
}

.start_page_h2 {
	text-align: center;
}

.food_menu {
	text-align: center;
}

.food_menu_row {
	line-height: 1.6;
}

.center {
	text-align: center;
}

a:link {
	color: rgb(185, 122, 87);
}

a:visited {
	color: rgb(238, 189, 117);
}

a:hover {
	color: rgb(75, 226, 226);
}

button {
	background-color: rgb(185, 122, 87);
	border: 2px solid black;
	color: white;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 5px;
}

.filebutton {
	background-color: rgb(185, 122, 87);
	border: 2px solid black;
	color: white;
	padding: 5px 10px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 5px;
}

input[type="file"] {
	display: none;
}

.osaDate {
	font-weight: bold;
	font-size: 20px;
}

.guestlistrow {
	cursor: pointer;
}

.breadcrumbs {
	font-style: italic;
	padding-top: 5px;
	padding-bottom: 10px;
}

/* Popup container */
.spinnerpopup {
	position: fixed;
	right: 0;
	z-index: 1;
	width: 55px;
	height: 50px;
	top: 52px;
}

.guestimage {
	cursor: pointer;
}

.gallery {
	padding-bottom: 30px
}

.gallerylist {
	display: flex;
	flex-wrap: wrap;
	padding: 0 4px;
}

.galleryimage {
	cursor: pointer;
	flex: 22%;
	padding: 0 4px;
	margin-bottom: 20px;
	background-color: rgb(250, 250, 250);
}

.galleryimage img {
  margin-top: 8px;
  vertical-align: middle;

}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
  .galleryimage {
    flex: 44%;
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .galleryimage {
    flex: 100%;
  }
}

.largeimage {
	position: fixed;
	z-index: 1;
	width: 100%;
	height: 100%;
	background-color: white;
	overflow-y: scroll;
	overflow-x: scroll;
}

.closelargeimage {
	color: Black;
	left: 10px;
	top: 20px;
	font-size: 30px;	
}

.selectedpage {
	font-weight: bold;
	background-color: #E0E0E0;
}

.remove {
	cursor: pointer;
}

.refreshwishlist {
	cursor: pointer;
}

.wishlistitem {
	padding-top: 30px;
}

.imagemetacopyright {
	font-style: italic;
}

.quizframe {
	padding: 10px;
}

.quizqueryoptionnumber {
	margin-bottom: 5px;
}

.quizqueryoption {
	cursor: pointer;
}

.quizqueryoptionselected {
	padding: 3px;
	background-color: lightgreen;
	cursor: pointer;
}

.CorrectTable {
	color: green;
	font-size: 25px;
}

.WrongTable {
	color: red;
	font-size: 25px;
}

.queryQuestioner {
	font-style: italic;
	text-align: right;
}